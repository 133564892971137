import { useRouter } from 'next/router';

import Toast from '@/components/global/Toast';
import { useSaleorAuthContext } from '@/lib/auth';
import { useUser } from '@/lib/hooks/useUser';
import { usePaths } from '@/lib/paths';

const LogoutToast = () => {
  const paths = usePaths();
  const router = useRouter();
  const { user } = useUser();
  const { isAuthenticating } = useSaleorAuthContext();

  const logout = !!router.query.logout;
  const logoutMessage =
    'You have succesfully logged out. Thank you for using Colophon, we hope to see you again soon!';

  if (!logout) return;
  if (isAuthenticating) return;

  const clearUrl = () => router.push(paths.$url(), undefined, { shallow: true });

  if (!user)
    return (
      <Toast onClose={() => clearUrl()} message={logoutMessage} autoClose autoCloseTime={5000} />
    );

  clearUrl();
  return;
};
export default LogoutToast;
