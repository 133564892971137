import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useStoryblok } from 'storyblok';

import { Layout } from '@/components';
import LogoutToast from '@/components/account/LogoutToast';
import Footer from '@/components/global/Footer';
import Header from '@/components/global/Header';
import BaseSeo from '@/components/seo/BaseSeo';
import { VERSION } from '@/lib/constants';
import { getStoryBlokProps } from '@/lib/storyblok/storyblokGetStaticProps';
import { getStoryBlokNavLinks } from '@/lib/storyblok/storyblokNavLinks';

import DynamicComponent from '../storyblok/components/DynamicComponent';

const Home = ({
  story,
  preview,
  resolve_relations
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const t = useIntl();

  const enableBridge = preview;
  const sbStory = preview
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useStoryblok(story, enableBridge, resolve_relations)
    : story;

  const globalLinks = getStoryBlokNavLinks(story);

  const {
    content: { headerTheme = '', SEO = {} },
    name = ''
  } = sbStory;

  return (
    <>
      <BaseSeo
        title={SEO?.title}
        description={SEO?.description}
        ogDescription={SEO?.og_description}
        ogTitle={SEO?.og_title}
        image={SEO?.og_image}
      />
      <Header
        blok={sbStory.content}
        navigation={globalLinks}
        headerTheme={headerTheme}
        pageName={name}
      />
      <LogoutToast />
      <main id="main" style={{ overflow: 'hidden' }}>
        {sbStory && <DynamicComponent blok={sbStory.content} />}
      </main>
      <Footer theme="grey-light" navigation={globalLinks.footerLinks} />
    </>
  );
};

export default Home;

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const pageSlug = 'home';
  const preview = context.preview || false;
  const resolveRelations = 'page.globalHeader,page.globalFooter';
  const story = await getStoryBlokProps({ pageSlug, context, VERSION, resolveRelations });

  return {
    props: {
      preview,
      story: story || null,
      resolve_relations: resolveRelations
    },
    revalidate: 60 * 60 // value in seconds, how often ISR will trigger on the server
  };
};

Home.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
